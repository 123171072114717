@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

button {
  padding: 0;
  background: none;
  border: none;
  outline: none;
}
line {
  stroke: #9996b3b3;
  stroke-width: 2;
}
.chainslistbtn {
  position: relative;
}
.chainslistdrowdown {
  position: absolute;
  top: 80px;
  min-width: 160px;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  border-radius: 10px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #a8a8a8;
}
/* css bad */
.index-module__item {
  border-radius: 8px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  flex: 1;
  position: relative;
  user-select: none;
  z-index: 2;
}

.index-module__menus .index-module__menu-arrow {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #bdbdbd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  width: 40px;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateZ(-100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateZ(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateZ(-100px);
}
